.container{
    background-color: black;
}
.item{
    padding: 1rem;
}

.explore{
  padding: 1rem;
  color: white;
  /* padding-top: 0; */
}

.header{
  text-align: center;
  font-size: 3rem;
  margin: 0;
  font-family: 'Jacques Francois Shadow';
  color: #ffb31c;
}

.desc{
  text-align: center;
  font-size: 1.25rem;
  margin: 0;
  color: rgba(223, 223, 223, 0.747);
}

.cat_div{
  background-image: url('/public/assets/bgCss.webp');

}

.categories{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    padding-top: 2rem;
    /* padding: 2rem; */
    width: 100%;
    /* background-image: url('/public/assets/bgCss.webp'); */
    backdrop-filter: brightness(20%);
}


.title{
    /* padding: 1rem; */
    /* background-color: rgba(240, 221, 49, 0.822); */
    backdrop-filter: brightness(30%);
    color: #ffb31c;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.small_line{
    width: 10%;
    background-color: white;
    padding: 2px;
    filter: brightness(50%);
}

.large_line{
    width: 60%;
    background-color: white;
    padding: 2px;
    filter: brightness(70%);
}

.text{
    width: 20%;
    padding-left: 1rem;
    text-align: start;
    font-size: 1.5rem;
}

.btn_div{
    width: 100%;
    display: flex;
    justify-content: end;
    /* padding-right: 2rem; */
}


.Btn {
    width: 140px;
    height: 40px;
    border: none;
    border-radius: 10px;
    background: linear-gradient(to right,#77530a,#ffd277,#77530a,#77530a,#ffd277,#77530a);
    background-size: 250%;
    background-position: left;
    color: #ffd277;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: 1s;
    overflow: hidden;
  }

  .curved_line {
    position: relative;
    width: 100%;
    height: 50px;
    overflow: hidden;
    /* clip-path: ellipse();   */
  
}

.curved_line::after {
    content: '';
    position: absolute;
    top: 25px; /* Move the curve downward */
    left: 0;
    width: 100%;
    height: 50px;
    border-top: 2px solid #0b2e4a;
    border-radius: 50%;
    /* Stronger and more visible downward shadow */
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4), 
                0 10px 20px rgba(0, 0, 0, 0.3), 
                0 5px 10px rgba(0, 0, 0, 0.2);
}


  
  


  .Btn::before {
    position: absolute;
    content: "View All";
    color: #ffd277;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 97%;
    height: 90%;
    border-radius: 8px;
    transition-duration: 1s;
    background-color: rgba(0, 0, 0, 0.842);
    background-size: 200%;
  }
  
  .Btn:hover {
    background-position: right;
    transition-duration: 1s;
  }
  
  .Btn:hover::before {
    background-position: right;
    transition-duration: 1s;
  }
  
  .Btn:active {
    transform: scale(0.95);
  }


  .btn {
    width: 140px;
    height: 40px;
    border: none;
    border-radius: 10px;
    background: linear-gradient(to right,#77530a,#ffd277,#77530a,#77530a,#ffd277,#77530a);
    background-size: 250%;
    background-position: left;
    color: #ffd277;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: 1s;
    overflow: hidden;
  }
  
  


  .btn::before {
    position: absolute;
    content: "عرض الكل";
    color: #ffd277;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 97%;
    height: 90%;
    border-radius: 8px;
    transition-duration: 1s;
    background-color: rgba(0, 0, 0, 0.842);
    background-size: 200%;
  }
  
  .btn:hover {
    background-position: right;
    transition-duration: 1s;
  }
  
  .btn:hover::before {
    background-position: right;
    transition-duration: 1s;
  }
  
  .btn:active {
    transform: scale(0.95);
  }