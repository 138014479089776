.container{
    padding: 2rem;
    /* width: 100% !important; */
    /* padding-top: 0rem; */
    /* overflow: hidden; */
}


.carousel{
    /* width: 100% !important; */
    /* padding: 2rem; */

}

.slide{
    height: 15rem !important;   
    /* height: 100%; */
    /* box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px; */

    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background-color: rgba(248, 248, 248, 0.411);
    border-radius: 15px;
    /* height: 100%; */
    /* height: 10rem; */
   /* width: 9rem !important; */
    color: white;
    padding: 2px;
}


.name{
    display: none;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    color: white !important;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.name2{
    /* display: none; */
    position: absolute;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    color: white !important;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    bottom: 0px;
    padding: 0.5rem;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.671);
}



.img{
    z-index: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    cursor: pointer;
    position: relative;
    
}

.img{
    /* box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px; */
}




.slide:hover .name {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 2rem;
    color: rgb(185, 182, 182);
    cursor: pointer;
    /* height: 4rem; */
}

.slide:hover .name2{
    display: none;
}

.slide:hover .img{
    filter: brightness(30%);
    
}








   @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  
  @keyframes blink-caret {
    50% {
      border-color: transparent;
    }
  }
  
  




   @media only screen and (max-width:576px){
    .button{
        display: block;
    }
    .img{
        /* filter: brightness(30%); */
    }

    .name{
        display: block !important;
    }

    .button{
        top: 40%;
        /* left: 34%; */
    }
    
   }