
.main{
    padding: 2rem;
    /* background-color: black; */
}

.games{
    width: 100%;
    /* padding: 2rem; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;

}


.card {
    overflow: visible;
    /* width: 12rem; */
    height: 18rem;
    padding: 5px;
   cursor: pointer;
    /* height: 254px; */
  }
  
  .content {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 300ms;
    box-shadow: 0px 0px 10px 1px #000000ee;
    border-radius: 5px;
    background: linear-gradient(#212121, #212121) padding-box,
              linear-gradient(125deg, transparent 35%,#ffb31c, #fafafa) border-box;
  border: 1px solid transparent;
  }

  .img{
    width: 100%;
    height: 90%;
  }
  
  .front, .back {
    background-color: #151515;
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .back {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  
  .back::before {
    position: absolute;
    content: ' ';
    display: block;
    width: 160px;
    height: 160%;
    /* background: linear-gradient(90deg, transparent, #ff9966, #ff9966, #ff9966, #ff9966, transparent); */
    
    animation: rotation_481 5000ms infinite linear;
    border-radius: 1px solid #ff9966;
  }
  
  .back_content {
    position: absolute;
    width: 99%;
    height: 99%;
    background-color: #151515;
    border-radius: 5px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 30px; */
  }

  .back_content strong{
    padding: 1rem;
  }
  
  .card:hover .content {
    transform: rotateY(180deg);
  }

  .img img{
    height: 100%;
    width: 100%;
    filter: brightness(50%);
  }
  
  /* @keyframes rotation_481 {
    0% {
      transform: rotateZ(0deg);
    }
  
    0% {
      transform: rotateZ(360deg);
    }
  } */
  
  .front {
    transform: rotateY(180deg);
    color: white;
  }
  
  .front .front_content {
    position: absolute;
    width: 100%;
    height: 100%;
    /* padding: 10px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .front_content .badge {
    background-color: #00000055;
    padding: 10px 20px;
    border-radius: 10px;
    backdrop-filter: blur(5px);
    width: fit-content;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 1rem;
    color: white;
  }
  
  .description {
    box-shadow: 0px 0px 10px 5px #00000088;
    width: 100%;
    padding: 10px;
    background-color: #00000099;
    backdrop-filter: blur(5px);
    border-radius: 5px;
    padding: 1rem;
    text-align: center;
  }
  
  .title {
    font-size: 1rem;
    /* max-width: 100%; */
    display: flex;
    justify-content: space-between;
  }
  
  .title p {
    width: 50%;
  }
  
  .card_footer {
    color: #ffffff88;
    margin-top: 5px;
    font-size: 8px;
  }
  
  .front .img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  /* .circle {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: #ffbb66;
    position: relative;
    filter: blur(15px);
    animation: floating 2600ms infinite linear;
  }
   */
  #bottom {
    background-color: #ff8866;
    left: 50px;
    top: 0px;
    width: 150px;
    height: 150px;
    animation-delay: -800ms;
  }
  
  #right {
    background-color: #ff2233;
    left: 160px;
    top: -80px;
    width: 30px;
    height: 30px;
    animation-delay: -1800ms;
  }
  
  @keyframes floating {
    0% {
      transform: translateY(0px);
    }
  
    50% {
      transform: translateY(10px);
    }
  
    100% {
      transform: translateY(0px);
    }
  }



  @media only screen and (max-width:1180px){
    .games{
      width: 100%;
      /* padding: 2rem; */
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 1rem;
  
  }
  }

  @media only screen and (max-width:928px){
    .games{
      width: 100%;
      /* padding: 2rem; */
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr ;
      gap: 1rem;
  
  }
  .card{
    height: 15rem;
  }
  }


  @media only screen and (max-width:728px){
    .games{
      width: 100%;
      /* padding: 2rem; */
      display: grid;
      grid-template-columns: 1fr 1fr ;
      gap: 1rem;
  
  }
  }


  
  @media only screen and (max-width:528px){
    .games{
      width: 100%;
      /* padding: 2rem; */
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
  
  }

  .main{
    padding: 1rem;
  }

  .card{
    height: 12rem;
  }
  }